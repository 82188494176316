import axios from "axios";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import {
  getApiKey,
  getApp,
  getAppUrl,
  getHost,
  getIsEmbedded,
  getIsFirstInstallation,
  getShop,
} from "../../components/GlobalVariables";
import store from "../../store/store";
import {
  setAllProducts,
  setCurrentSessions,
  setCurrentSubscription,
  setDisableTabs,
  setLocalproducts,
  setPaginationInfo,
  setSelected,
  setSelectedCategories,
  setShopifyProducts,
} from "../../store/slices/productsSlice";
import {
  Product,
  ShopifyVariant,
  Variant,
} from "../../interfaces/productIntegration";
import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { subscriptionDetails } from "../../views/Subscription";

const getSelectedCategories = async () => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  if (!shopName) return;
  try {
    const response = await axios.get(
      `${app_server_url}/shopify-plugin/makeup-categories?shop=${shopName}`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );

    if (response?.data) store.dispatch(setSelectedCategories(response.data));
  } catch (e) {}
};

const handleTabChange = (selectedTabIndex: number) => {
  console.log("tabis", selectedTabIndex, store.getState().products.selected);
  store.dispatch(setSelected(selectedTabIndex));
};

const fetchLocalProducts = async (save?:boolean) => {
  let LocalProducts: Product[] = [];
  const shopName = getShop();
  const app_server_url = getAppUrl();
  try {
    const response = await axios.post(
      `${app_server_url}/shopify-plugin/get-products?shop=${shopName}`,
      {},
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    LocalProducts = (response.data as any[]).map((item) => {
      return {
        id: item.productId,
        productId: item.productId,
        productName: item.productName,
        storeName: item.storeName,
        makeup: item.makeup,
        variants: item.variants,
        imageUrl: item?.imageUrl || "",
      } as Product;
    });
  } catch (error) {
    console.error("Failed to fetch local products:", error);
  }
  const Save = save ?? true 
  if(Save){
    store.dispatch(setLocalproducts(LocalProducts));
  }
  return LocalProducts ? LocalProducts : [];
};

const postSelectedCategories = async (categories: string[]) => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  try {
    axios
      .post(
        `${app_server_url}/shopify-plugin/makeup-categories?shop=${shopName}`,
        categories,
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
            skip_zrok_interstitial: "69420",
            authorization: `Bearer ${await getSessionToken(getApp())}`,
          },
        }
      )
      .then(() => {
        fetchLocalProducts();
      });
  } catch (e) {}
};

const addProduct = async (productIds: string[]) => {
  const ShopifyProducts = store.getState().products.shopifyProducts;
  const Localproducts = store.getState().products.localproducts;
  const products: any = getProductsByIds(productIds);
  let tempShopifyProducts = [...ShopifyProducts];
  for (const product of products) {
    tempShopifyProducts = tempShopifyProducts.filter(
      (prod) => prod?.productId !== product.productId
    );
  }
  store.dispatch(setShopifyProducts(tempShopifyProducts));
  store.dispatch(setLocalproducts([...Localproducts, ...products]));
};

const getProductsByIds = (ids: string[]) => {
  const allProducts = store.getState().products.allProducts;
  const products = [];
  for (const id of ids) {
    products.push(allProducts.find((prod) => prod?.productId === id));
  }
  return products;
};

const deleteProduct = async (productIds: string[]) => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  const ShopifyProducts = store.getState().products.shopifyProducts;
  const Localproducts = store.getState().products.localproducts;
  const products: any = getProductsByIds(productIds);
  try {
    let tempLocalProducts = [...Localproducts];
    for (const product of products) {
      tempLocalProducts = tempLocalProducts.filter(
        (val) => val?.productId !== product?.productId
      );
    }
    store.dispatch(setLocalproducts(tempLocalProducts));
    store.dispatch(setShopifyProducts([...ShopifyProducts, ...products]));
    await axios.post(
      `${app_server_url}/shopify-plugin/delete-products?shop=${shopName}`,
      productIds,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
  } catch (error) {
    console.error("Failed to delete product:", error);
  }
};

const saveProductInfo = async (productIds: string[]) => {
  const shopName = getShop();
  const app_server_url = getAppUrl();

  const Localproducts = store.getState().products.localproducts;
  let products: any = [];
  for (const id of productIds) {
    products.push(Localproducts.find((prod) => prod.productId === id));
  }
  try {
    await axios.post(
      `${app_server_url}/shopify-plugin/post-products?shop=${shopName}`,
      products,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
  } catch (error) {
    console.error("Failed to save product edit:", error);
  }
};

const fetchAllShopifyProducts = async () => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  const pageCursor = store.getState().products.pageCursor;
  const searchVal = store.getState().products.searchVal;
  if (!shopName) return;
  try {
    if (!app_server_url || !shopName) return;
    let url = `${app_server_url}/shopify-plugin/shopifyProducts?shop=${shopName}`;
    if (pageCursor.cursor && pageCursor.step) {
      url = url + "&cursor=" + pageCursor.cursor + "&page=" + pageCursor.step;
    }
    if (searchVal) url = url + "&search=" + searchVal;

    const response = await axios.get(url, {
      headers: {
        "ngrok-skip-browser-warning": "69420",
        skip_zrok_interstitial: "69420",
        authorization: `Bearer ${await getSessionToken(getApp())}`,
      },
    });
    const LocalProducts = await fetchLocalProducts(false);
    // store.dispatch(setLocalproducts(LocalProducts));
    const tempShopifyProducts: Product[] = [];
    store.dispatch(setPaginationInfo(response.data.data.products.pageInfo));
    const AllShopifyProducts = (
      response.data.data.products.nodes as any[]
    )?.map((item) => {
      const product = {
        id: item.legacyResourceId.toString(),
        productId: item.legacyResourceId.toString(),
        productName: item.title,
        storeName: shopName,
        makeup: "Lipstick",
        variants: item.variants.nodes.map((variant: ShopifyVariant) => {
          return {
            variantId: variant.legacyResourceId.toString(),
            variantName: variant.title,
            color: variant?.color || "#000000",
          } as Variant;
        }),
        imageUrl: item?.featuredMedia?.preview?.image?.url,
      } as Product;
      const isPresentInLocal = LocalProducts.find(
        (prod) => prod.productId === item.legacyResourceId.toString()
      );
      if (!isPresentInLocal) tempShopifyProducts.push(product);
      return product;
    });
    store.dispatch(setShopifyProducts(tempShopifyProducts));
    store.dispatch(setAllProducts(AllShopifyProducts || []));
  } catch (error) {
    console.error("Failed to fetch shopify products:", error);
  }
};

const fetchSubscriptionData = async () => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  const isFirstInstallation = getIsFirstInstallation();
  console.log("currentSu1", shopName);
  if (!shopName) return;
  try {
    const response = await axios.get(
      `${app_server_url}/shopify-plugin/recurring_application_charges?shop=${shopName}`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    console.log("currentSub", response.data);
    if (!response?.data){
      store.dispatch(setCurrentSubscription(subscriptionDetails[0].key));
    }
    else store.dispatch(setCurrentSubscription(response.data.planName));
    if (
      !isFirstInstallation &&
      (response?.data?.planName === "free" || !response?.data?.planName)
    ) {
      handleTabChange(4);
      store.dispatch(setDisableTabs(true));
    } else {
      handleTabChange(0);
      store.dispatch(setDisableTabs(false));
    }
  } catch (e) {
    console.log(e);
  }
};

const addSubscription = async (price: number, name: string) => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  try {
    const response = await axios.post(
      `${app_server_url}/shopify-plugin/recurring_application_charges?shop=${shopName}&host=${getHost()}`,
      {
        planPrice: price,
        planName: name,
        storeName: shopName,
      },
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    if (response.data.url) {
      if (getIsEmbedded() === 1) {
        const config = {
          apiKey: getApiKey(),
          host: getHost(),
          forceRedirect: true,
        };
        const app = createApp(config);
        const redirect = Redirect.create(app);
        redirect.dispatch(Redirect.Action.REMOTE, response.data.url);
      } else window.location.replace(response.data.url);
    }
  } catch (e) {
    console.log(e);
  }
};

const cancelSubscription = async (price: number, name: string) => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  try {
    const response = await axios.delete(
      `${app_server_url}/shopify-plugin/recurring_application_charges?shop=${shopName}`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    if (response?.status === 200 && response?.data !== false) {
      handleTabChange(4);
      store.dispatch(setDisableTabs(true));
      store.dispatch(setCurrentSubscription(""));
    }
  } catch (e) {
    console.log(e);
  }
};

const getSessions = async () => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  if (!shopName) return;
  try {
    const response = await axios.get(
      `${app_server_url}/shopify-plugin/sessions?shop=${shopName}`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    store.dispatch(setCurrentSessions(response.data));
  } catch (e) {
    console.log(e);
  }
};

export {
  getSessions,
  saveProductInfo,
  getProductsByIds,
  deleteProduct,
  fetchAllShopifyProducts,
  fetchSubscriptionData,
  addSubscription,
  cancelSubscription,
  addProduct,
  postSelectedCategories,
  fetchLocalProducts,
  getSelectedCategories,
  handleTabChange,
};
