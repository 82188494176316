import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPageCursor, Product } from "../../interfaces/productIntegration";
import { RootState } from "../store";
import { ITheme } from "../../interfaces/globals";

export interface ProductsState {
  shopifyProducts: Product[];
  localproducts: Product[];
  allProducts: Product[];
  currentSubscription: string;
  currentSessions: number;
  selectedCategories: any;
  disableTabs: boolean;
  showCancelConfirmPopup: boolean;
  paginationInfo: any;
  pageCursor: IPageCursor;
  searchVal: string;
  selected: number;
  themes: ITheme[];
  activeTheme: ITheme | null;
  blockNextToSavePrdts:boolean
}

const initialState: ProductsState = {
  shopifyProducts: [],
  localproducts: [],
  allProducts: [],
  currentSubscription: "",
  currentSessions: 0,
  selectedCategories: null,
  disableTabs: false,
  showCancelConfirmPopup: false,
  paginationInfo: null,
  pageCursor: { cursor: "", step: "" },
  searchVal: "",
  selected: 0,
  themes: [],
  activeTheme: null,
  blockNextToSavePrdts: false
};

const productsSlice = createSlice({
  name: "Products",
  initialState,
  reducers: {
    setShopifyProducts(state, action) {
      state.shopifyProducts = action.payload;
    },
    setLocalproducts(state, action) {
      state.localproducts = action.payload;
    },
    setAllProducts(state, action) {
      state.allProducts = action.payload;
    },
    setCurrentSubscription(state, action) {
      state.currentSubscription = action.payload;
    },
    setCurrentSessions(state, action) {
      state.currentSessions = action.payload;
    },
    setSelectedCategories(state, action) {
      state.selectedCategories = action.payload;
    },
    setDisableTabs(state, action) {
      state.disableTabs = action.payload;
    },
    setShowCancelConfirmPopup(state, action) {
      state.showCancelConfirmPopup = action.payload;
    },
    setPaginationInfo(state, action) {
      state.paginationInfo = action.payload;
    },
    setPageCursor(state, action) {
      state.pageCursor = action.payload;
    },
    setSearchVal(state, action) {
      state.searchVal = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setThemes(state, action) {
      state.themes = action.payload;
    },
    setActiveTheme(state, action) {
      state.activeTheme = action.payload;
    },
    setBlockNextToSavePrdts(state,action){
      state.blockNextToSavePrdts = action.payload
    }
  },
});

export const {
  setBlockNextToSavePrdts,
  setActiveTheme,
  setPaginationInfo,
  setSelected,
  setPageCursor,
  setShowCancelConfirmPopup,
  setDisableTabs,
  setSelectedCategories,
  setCurrentSessions,
  setCurrentSubscription,
  setShopifyProducts,
  setLocalproducts,
  setAllProducts,
  setSearchVal,
  setThemes,
} = productsSlice.actions;

export const selectProductState = (state: RootState) => state.products;

export default productsSlice.reducer;
