import React from "react";
import classes from "./Congratulations.module.scss";
import { useNavigate } from "react-router-dom";

const Congratulations = () => {
    const navigate = useNavigate()
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <div className={classes.bottom}>
            <span className={classes.seperator}></span>
            <div className={classes.footer}>
                <span>Have queries or concerns?</span> 
                <span>Drop us an email at info@metadome.ai</span>
            </div>
        </div>
        <div className={classes.left}>
          <span className={classes.head}>
          Track customer usage analytics of VTO
          </span>
          <span className={classes.desc}>
          Usage of VTO and analytics can be tracked on your dashboard.
          </span>
        </div>
        <div className={classes.right}>
          <img src="./assets/icons/congrats.svg"/>
          <span className={classes.head}>Congratulations!</span>
          <span>You have now configured Metadome VTO on your store.</span>
          <button onClick={() => {
                navigate("/new");
            }}>Go to Dashboard</button>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
