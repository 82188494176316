import { useState } from 'react';
import { CaretDownIcon, CaretUpIcon } from '@shopify/polaris-icons';
import classes from './faq.module.scss'
import {
    Button, Text, Divider, Card, Icon, List
} from '@shopify/polaris';

export default function FAQ() {
    const [faqs, setFaqs] = useState([
        {
            question: 'How does Metadome.ai’s Virtual Try-On Work?',
            answer: 'Metadome.ai Virtual Try-On runs on a base that is enabled by JavaScript API. The app reduces guesswork in color and shade matching for makeup and foundation, allowing customers to make decisions faster.',
            type: 'default',
            open: true
        },
        {
            question: 'How can I renew my subscription?',
            answer: 'To ensure uninterrupted access to makeup try-on for your customers, your subscription automatically renews either monthly or yearly.If you like to change your subscription plan, please proceed to “Choose Plan” page.',
            type: 'default',
            open: false
        },
        {
            question: 'What does your analytics cover?',
            answer: 'Your metrics can be accessed from a Mixpanel dashboard. The link to your Mixpanel dashboard will be shared with you shortly after registration. The base plan covers 4 key metrics including, DAU, MAU, Clicks, and Users engagement times. The pro plan covers everything in the base plan along with product click metrics, and the ability to customize your dashboard.',
            type: 'default',
            open: false
        },
        {
            question: 'New Features coming soon!',
            answer: 'We have exciting new features coming soon! Our foundation finder makes it super easy for your customers to find their perfect shade.  Boost your average order value with preset looks that create automated looks with a bundle of your products. We’re bringing exciting GenAI capabilities too!',
            type: 'default',
            open: false
        },
        {
            question: 'How to integrate the VTO button into your website',
            answer: `After installing the app and selecting products, customize the theme template. Navigate to the Products page, click Add block, switch to the Apps section, and select Metadome's Virtual Try-On embedded app block. Then, locate the Virtual Try-On CTA on the product page and position it as desired.Remember, Virtual try-on button will only be visible on the selected and saved products.`,
            type: 'default',
            open: false
        },
        {
            question: ' Step-By-Step guide',
            answers: [
                `Install the App: After installation, you'll automatically enter the free plan. Go to Product Integration tab`,
                'Add Products: Select the products you want to enable for virtual try-on. Choose the relevant category for each product.',
                'Select Categories: Choose category for each selected product',
                `Enter Hex Codes: Input the product's hex code, ensuring it starts with a # symbol.`,
                `Save Changes: Click Save Products after each update to apply the changes to the try-on link on product description page`,
            ],
            type: 'list',
            open: false
        }
    ])


    return <div className={classes.container}>
        <Text as='h3' fontWeight='bold'>FAQ</Text>
        <div className={classes.faqContainer}>
            <div className={classes.section1}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                    <Text as='h6' fontWeight="bold">Frequently Asked Question</Text>
                    <Text variant="bodySm" as='p'>THere are our most frequently asked questions. For more details about Metadome's Virtual Try-On or if your question isn’t covered, please contact us - gaurav@metadome.ai</Text>
                </div>
            </div>
            <div className={classes.section2}>
                <Card>
                    <div className={classes.faqs}>
                        {faqs.map((faq, index) =>
                            <div key={faq.question} style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => {
                                    const temp = [...faqs]
                                    temp[index].open = !temp[index].open
                                    setFaqs(temp)
                                }}>
                                    <Button fullWidth={true} size="large" textAlign='left' variant="tertiary">
                                        {faq.question}
                                    </Button>
                                    <Icon source={!faq.open ? CaretDownIcon : CaretUpIcon} tone="base" />
                                </div>
                                {faq.open &&
                                    faq.type === 'list' ?
                                    <List type='number' >
                                        {faq.answers?.map((point: string) => <List.Item>
                                            <Text as='p' variant='bodySm' fontWeight='bold'>{point.split(':')[0]}</Text>
                                            <Text as='p' variant='bodySm' >{point.split(':')[1]}</Text>
                                        </List.Item>)}
                                    </List>
                                    :
                                    <div style={{ paddingLeft: '15px' }}>
                                        <Text as='p' variant="bodyMd">{faq.answer}</Text>
                                    </div>
                                }
                                <Divider borderColor="border-inverse" />
                            </div>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    </div>
}