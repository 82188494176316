import { LegacyCard, Tabs, Text } from "@shopify/polaris";
import { useState, useCallback, useEffect } from "react";
import Home from "./home";
import ProductIntegration from "./ProductIntegration";
import axios from "axios";
import {
  getApiKey,
  getApp,
  getAppUrl,
  getHost,
  getIsEmbedded,
  getIsFirstInstallation,
  getShop,
} from "../components/GlobalVariables";
import FAQ from "./faq";
import Subscription, { subscriptionDetails } from "./Subscription";

import ContactUs from "./contactus";
import classes from "./try.module.scss";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import {
  addProduct,
  addSubscription,
  cancelSubscription,
  deleteProduct,
  fetchAllShopifyProducts,
  fetchSubscriptionData,
  getSelectedCategories,
  getSessions,
  handleTabChange,
  postSelectedCategories,
  saveProductInfo,
} from "../services/apis/pageIntegeration";
import { useSelector } from "react-redux";
import { selectProductState } from "../store/slices/productsSlice";

export default function Dashboard() {
  const isFirstInstallation = getIsFirstInstallation();
  const {
    currentSubscription,
    currentSessions,
    disableTabs,
    showCancelConfirmPopup,
    selected,
  } = useSelector(selectProductState);

  const Analytics = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <Text as="h3" fontWeight="bold">
          Analytics
        </Text>
        <img
          loading="lazy"
          style={{ width: "100%" }}
          src="https://virt-assets.metadome.ai/logo/analytics.png"
          alt="Analytics Logo"
        />
      </div>
    );
  };

  const tabs = [
    {
      id: "home",
      content: "Home",
      panelID: "home",
      component: (
        <Home
          currentPlan={subscriptionDetails.find(
            (plan) => plan.key === currentSubscription
          )}
          goToProducts={() => handleTabChange(1)}
          goToAnalytics={() => handleTabChange(2)}
          goToContactUs={() => handleTabChange(5)}
          goToPlans={() => handleTabChange(4)}
          goToFaq={() => handleTabChange(3)}
          currentSessions={currentSessions}
        />
      ),
    },
    {
      id: "product",
      content: "Product Integration",
      panelID: "product",
      component: <ProductIntegration state="dashboard"/>
    },
    {
      id: "analytics",
      content: "Analytics",
      panelID: "analytics",
      component: <Analytics />,
    },
    {
      id: "faq",
      content: "FAQ",
      panelID: "faq",
      component: <FAQ />,
    },
    {
      id: "subscription",
      content: "Choose Plan",
      panelID: "subscription",
      component: (
        <Subscription
          isFirstInstallation={isFirstInstallation}
          goToContactUs={() => handleTabChange(5)}
          currentSubscription={currentSubscription}
          showCancelConfirmPopup={showCancelConfirmPopup}
          addSubscription={addSubscription}
          cancelSubscription={cancelSubscription}
        />
      ),
    },
    {
      id: "contactus",
      content: "Contact Us",
      panelID: "contactus",
      component: <ContactUs />,
    },
  ];

  return (
    <Tabs
      tabs={tabs}
      selected={selected}
      onSelect={handleTabChange}
      fitted
      disabled={disableTabs}
    >
      <div className={classes.spacing}>
        <LegacyCard.Section
          title={tabs[selected].component}
        ></LegacyCard.Section>
      </div>
    </Tabs>
  );
}
