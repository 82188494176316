import React, { useState } from "react";
import Onboarding from "../../components/Onboarding/Onboarding";
import { fetchThemes } from "../../utils/api";

const InitialJourney = () => {
  return (
    <div>
      <Onboarding />
    </div>
  );
};

export default InitialJourney;
