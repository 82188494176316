import { ActionList, Button, Card, Page, Popover } from "@shopify/polaris";
import { useState, useCallback, useMemo, useEffect, useLayoutEffect } from "react";
import classes from "./Onboarding.module.scss";
import { categories, onboardingSteps } from "../../utils/constants";
import ProductIntegration from "../../views/ProductIntegration";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import {
  getApp,
  getAppExtensionId,
  getAppUrl,
  getHost,
  getIsFirstInstallation,
  getShop,
} from "../GlobalVariables";
import axios from "axios";
import { subscriptionDetails } from "../../views/Subscription";
import { useNavigate } from "react-router-dom";
import Welcome from "../Welcome/Welcome";
import { checkInstallations, fetchThemes } from "../../utils/api";
import { Product, Variant } from "../../interfaces/productIntegration";
import store from "../../store/store";
import { useSelector } from "react-redux";
import {
  selectProductState,
  setActiveTheme,
  setSelected,
  setSelectedCategories,
  setThemes,
} from "../../store/slices/productsSlice";
import {
  getSessions,
  getSelectedCategories,
  fetchAllShopifyProducts,
  fetchSubscriptionData,
  fetchLocalProducts,
  postSelectedCategories,
} from "../../services/apis/pageIntegeration";
import { ITheme } from "../../interfaces/globals";
import Congratulations from "../Congratulations/Congratulations";
import { addCategories } from "../../store/slices/categoriesSlice";

let installInterval: NodeJS.Timer

function Onboarding() {
  const [activeStep, setActiveStep] = useState(1);
  const [active, setActive] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);
  const navigate = useNavigate();
  const [selectedTab,setSelectedTab] = useState("1")
  const [buttonInstallationStatus,setButtonInstallationStatus] = useState({
    vto: false,
    fab: false
  })
  const [hoverTheme,setHoverTheme] = useState(0)
  const [chooseTheme,setChooseTheme] = useState(false)
  const [onboard,setOnboard] = useState(false)

  const app_server_url = getAppUrl();
  const isFirstInstallation = getIsFirstInstallation();
  const shopName = getShop();
  const {
    currentSubscription,
    currentSessions,
    disableTabs,
    showCancelConfirmPopup,
    pageCursor,
    searchVal,
    selected,
    themes,
    activeTheme,
    localproducts,
    blockNextToSavePrdts
  } = useSelector(selectProductState);
  const addedProductsData = JSON.parse(JSON.stringify(localproducts || []));

  const checkInstallationCondition = async() => {
    const y = await checkInstallations()
    return y
  }

  const checkLocalProducts = async() => {
      const prdts = await fetchLocalProducts()
      return prdts
  }

  useEffect(
  () => {
    const callback = async () => {
      let checkInstallation = false
      let checkLocalPrdt = false
      await checkInstallationCondition().then((resp)=>{
        if(resp?.appBlock || resp?.appEmbedBlock){
          checkInstallation = true
        }
        console.log("check1",checkInstallation,checkLocalPrdt)
      })
      await checkLocalProducts().then((ob) => {
        if(ob){
          checkLocalPrdt = true
        }
        console.log("check2",checkInstallation,checkLocalPrdt)
      })
      const currentPlan = subscriptionDetails.find(
        (plan) => plan.key === currentSubscription
      );
      console.log("check",checkInstallation,checkLocalPrdt,isFirstInstallation,currentPlan)
     if(!isFirstInstallation && currentPlan?.key === "free"){
        store.dispatch(setSelected(4));
        navigate("/new")
      }
      if(checkInstallation && checkLocalPrdt){
        navigate("/new")
        setOnboard(false)
      } else {
        setOnboard(true)
      }
    }

    callback()
  }, [currentSubscription])

  useEffect(() => {
    getSessions();
    getSelectedCategories();
  }, [app_server_url, shopName]);

  useEffect(() => {
    fetchAllShopifyProducts();
  }, [app_server_url, shopName, pageCursor, searchVal]);

  useEffect(() => {
    fetchSubscriptionData();
  }, [isFirstInstallation, shopName, app_server_url]);

  useEffect(() => {
    fetchThemes();
  }, []);

  useEffect(() => {
    let selectCats:any[] = []
    categories.forEach((ob) => {
      selectCats.push(ob.value)
    })
    store.dispatch(setSelectedCategories(selectCats));
    postSelectedCategories(selectCats)

  },[])

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const activator = (
    <button onClick={toggleActive} className={`${classes.dropdownbtn} ${active && classes.active}`}>
      <span>{activeTheme === null
        ? "Choose a Theme"
        : `${activeTheme.name}`}</span>
      <img src="./assets/icons/downarrow.svg"/>  
    </button>
  );

  const activeStepData = useMemo(() => {
    return onboardingSteps.find((ob) => ob.id === activeStep);
  }, [activeStep]);

  const getImagebyTab = () => {
    if(selectedTab === "1"){
      return "themeimg.png"
    }
    if(selectedTab === "2"){
      return "installvtoimg.png"
    }
    if(selectedTab === "3"){
      return "fabimg.png"
    }
  }

  const getSortedThemes = () => {
    const x = themes.filter((ob) => ob.role === "MAIN")
    const rem = themes.filter((ob) => ob.role !== "MAIN")
    return [...x,...rem]
  }

  const installVto = buttonInstallationStatus?.vto ?? false
  const installFab = buttonInstallationStatus?.fab ?? false

  if(!onboard){
    return null
  }

  return (
    <div className={classes.wrapper}>
      <div className={`${classes.formbody} ${!showWelcomeScreen && classes.further}`}>
        {!showWelcomeScreen && activeStep !==3 && (
          <div className={classes.top}>
             <div
                  className={`${classes.step}`}
                  // onClick={() => setActiveStep(step.id)}
                >
                  <span className={classes.stepname}>{`${activeStepData?.name}`}</span>
                  <span className={classes.stepcount}>{`${activeStep}/${onboardingSteps.length-1}`}</span>
              </div>
              <div className={classes.progress}>
                <span className={classes.fill} style={{
                  width: `${(activeStep/(onboardingSteps.length-1)) * 100}%`
                }}></span>
              </div>
          </div>
        )}
        {/* {!showWelcomeScreen && <Heading />} */}
        {!showWelcomeScreen && (
          <div className={classes.middle}>
            {activeStep === 1 && (
              <div className={classes.left}>
                <div className={`${classes.first} ${selectedTab === "1" && classes.active}`} id="1" onClick={() => setSelectedTab("1")}>
                <div className={classes.top}>
                    <span className={classes.head}>
                    Choose your store’s theme from the drop down. 
                    </span>
                    <span className={classes.desc}>
                    Watch the video on the right to see how to find your store’s theme. 
                    </span>
                  </div>
                  <Popover
                    active={active}
                    activator={activator}
                    onClose={toggleActive}
                    sectioned
                    fullWidth
                  >
                    <div
                      className={classes.elems}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: '200px',
                        overflow: 'scroll',
                      }}
                    >
     
                      {getSortedThemes().map((ob: ITheme) => {
                        return (
                          <div className={classes.elewrapper} style={{
                            width:'100%',
                            padding:'10px 20px',
                            background: ob?.id === hoverTheme ? 'rgba(247, 247, 247, 1)': '',
                            cursor: "pointer"
                          }} onMouseEnter={() => {
                            setHoverTheme(ob?.id)
                          }}
                          onClick={() => {
                            store.dispatch(setActiveTheme(ob))
                            toggleActive()
                            setChooseTheme(true)
                            clearInterval(installInterval)
                            checkInstallations(ob?.gid).then((resp) => {
                              setButtonInstallationStatus({
                                vto: resp?.appBlock ?? false,
                                fab: resp?.appEmbedBlock ?? false
                              })
                            })
                            }}>
                            {/* <img src="./assets/icons/tick.svg"/> */}
                             <span
                             style={{
                              fontWeight: ob?.role === "MAIN" ? '600': "initial"
                             }}
                            className={`${classes.elem} ${ob?.role === "MAIN" && classes.mainelem}`}
                          >
                            {ob?.name}{ob?.role === "MAIN" ? "(Active)" : ""}
                          </span>
                          </div>
                         
                        );
                      })}
                    </div>
                  </Popover>
                </div>
                <div className={`${classes.first} ${(selectedTab === "2" || installVto) && classes.active}`}id="2" onClick={() => setSelectedTab("2")} style={{
                  opacity: !chooseTheme ? '0.4' : '1',
                  pointerEvents: !chooseTheme ? 'none' : "all",
                  cursor: !chooseTheme ? 'default' : 'pointer'
                }}>
                <div className={classes.top}>
                    <span className={classes.head}>
                      {installVto && <img src="./assets/icons/tick.svg"/>}
                      <span>Install the Metadome VTO</span>
                    </span>
                    <span className={classes.desc}>
                    Install our plugin. You will be redirected to your store. Click ’save’ to enable VTO and come back here for the next steps.
                    </span>
                  </div>
                  <button className={`${classes.vtobtn} ${installVto && classes.disable}`}
                    onClick={() => {
                      if(installVto){
                        return
                      }
                      window.open(
                        `https://${getShop()}/admin/themes/${
                          activeTheme === null ? "current" : activeTheme?.id
                        }/editor?template=product&addAppBlockId=${getAppExtensionId()}/VTOAppBlock&target=mainSection`
                      );
                      if(installInterval){
                        clearInterval(installInterval)
                      }
                      installInterval =  setInterval(() => {
                        checkInstallations(activeTheme?.gid).then((resp) => {
                          setButtonInstallationStatus({
                            vto: resp?.appBlock ?? false,
                            fab: resp?.appEmbedBlock ?? false
                          })
                           if(resp?.appBlock && resp?.appEmbedBlock){
                            clearInterval(installInterval)
                          }
                        })
                      },1000)
                    }}
                  >
                    Install VTO
                  </button>
                </div>
                <div className={`${classes.first} ${(selectedTab === "3" || installFab) && classes.active}`}id="3" onClick={() => setSelectedTab("3")} style={{
                  opacity: !chooseTheme ? '0.4' : '1',
                  pointerEvents: !chooseTheme ? 'none' : "all",
                  cursor: !chooseTheme ? 'default' : 'pointer'
                }}>
                  <div className={classes.top}>
                    <span className={classes.head}>
                      {installFab && <img src="./assets/icons/tick.svg"/>}
                      <span>Add a Floating Action Button (FAB) on the Product Page</span>
                    </span>
                    <span className={classes.desc}>
                    Install VTO FAB. You will be redirected to your store. Click ’save’ to enable the button and come back here for the next steps.
                    </span>
                  </div>
                 
                  <button  className={`${classes.vtobtn} ${installFab && classes.disable}`}
                    onClick={() => {
                      if(installFab){
                        return
                      }
                      window.open(
                        `https://${getShop()}/admin/themes/${
                          activeTheme === null ? "current" : activeTheme?.id
                        }/editor?context=apps&template=product&activateAppId=${getAppExtensionId()}/VTOAppEmbedBlock`
                      );
                      if(installInterval){
                        clearInterval(installInterval)
                      }
                      installInterval =  setInterval(() => {
                        checkInstallations(activeTheme?.gid).then((resp) => {
                          setButtonInstallationStatus({
                            vto: resp?.appBlock ?? false,
                            fab: resp?.appEmbedBlock ?? false
                          })
                          if(resp?.appBlock && resp?.appEmbedBlock){
                            clearInterval(installInterval)
                          }
                        })
                      },1000)
                    }}
                  >
                    Install VTO FAB
                  </button>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div className={classes.right}>
                {/* <video src={`/assets/sunroof_en_audi.mp4`} autoPlay /> */}
                <img src={`./assets/${getImagebyTab()}`}/>
              </div>
            )}
            {activeStep === 2 && <ProductIntegration state="onboarding" />}
            {activeStep === 3 && (
            <Congratulations/>
            )}
          </div>
        )}
        {showWelcomeScreen && <Welcome setShowWelcomeScreen={setShowWelcomeScreen} />}
        {!showWelcomeScreen && activeStep !== onboardingSteps.length && <div
          className={`${classes.footer} ${
            showWelcomeScreen && classes.welcome
          }`}
        >
          { (
            <button
              className={`${classes.footerbtn} ${classes.backbtn} ${activeStep === 1 && classes.disable}`}
              onClick={() => {
                if (activeStep > 1) {
                  setActiveStep(activeStep - 1);
                }
              }}
            >
              Back
            </button>
          )}
          <button
             className={`${classes.footerbtn} ${activeStep === 1 && (installVto && activeTheme !== null) && classes.active} ${activeStep === 2 && addedProductsData.length > 0 && !blockNextToSavePrdts && classes.active }`}
            onClick={() => {
              if (showWelcomeScreen) {
                setShowWelcomeScreen(false);
              } else {
                if (activeStep <= onboardingSteps.length - 1) {
                  if(activeStep === 1){
                    if((buttonInstallationStatus?.vto || buttonInstallationStatus?.fab) && activeTheme !== null){
                      clearInterval(installInterval)
                      setActiveStep(activeStep + 1);
                    }
                  }
                  else if (activeStep === 2){
                    if(addedProductsData.length > 0){
                      setActiveStep(activeStep + 1); 
                    }
                  } 
                  else {
                    setActiveStep(activeStep + 1);
                  }
      
                }
                // if (activeStep === onboardingSteps.length) {
                //   navigate("/new");
                // }
              }
            }}
          >
            {activeStep === onboardingSteps.length-1 ? "Finish" : "Next"}
          </button>
        </div>}
      </div>
    </div>
  );
}

export default Onboarding;
