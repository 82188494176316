import React from "react";
import ReactDOM from "react-dom";
import { createRoot, Root } from "react-dom/client";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json"; // Adjust the locale as needed
import "@shopify/polaris/build/esm/styles.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import "./global.css";
import { VerifyShopify } from "./components/useUrlParams";
require("./utils/sentry");
const temp = document.getElementById("root");
if (temp) {
  const root = createRoot(temp);
  VerifyShopify().then(() => {
    root.render(
      <Provider store={store}>
        <AppProvider i18n={translations}>
          <App />
        </AppProvider>
      </Provider>
    );
  });
}
