import { configureStore } from "@reduxjs/toolkit";
import clientReducer from "./slices/clientSlice";
import categoriesReducer from "./slices/categoriesSlice";
import filtersReducer from "./slices/filtersSlice";
import productsReducer from "./slices/productsSlice";

const store = configureStore({
  reducer: {
    client: clientReducer,
    categories: categoriesReducer,
    filters: filtersReducer,
    products: productsReducer,
  },
});
export type RootState = ReturnType<typeof store.getState>;
export default store;
