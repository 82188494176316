import axios from "axios";
import {
  getApiKey,
  getApp,
  getAppUrl,
  getHost,
  getShop,
  setApiKey,
  setAppExtensionId,
  setAppUrl,
  setHost,
  setIsEmbedded,
  setIsFirstInstallation,
  setIsInstallationRequired,
  setIsShopify,
  setIsVerificationComplete,
  setShop,
} from "./GlobalVariables";
import { createApp } from "@shopify/app-bridge";
import { Redirect } from "@shopify/app-bridge/actions";
import { getSessionToken } from "@shopify/app-bridge/utilities";

export const VerifyShopify = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramsObject: { [key: string]: string } = {};

    urlParams.forEach((value, key) => {
      paramsObject[key] = value;
    });
    const segments = window.location.href.split("/");
    const uselink: string = segments[segments.indexOf("links") + 1];

    setApiKey(
      // uselink === "main"
      //   ? "4e6ba021f781cd0a9960b24f02c88079"
      //   : uselink === "staging"
      //     ? "04115bbdd2fba0c109c2c14dd46cce51"
      //     : (uselink === "dev" || uselink === "dev-new-testing")
      //       ? "0ef1800613bb74b4cada2443876d04a6"
      //       // : "6e98b4810f8a341b9c0031c4153564f4"
      //       : "0ef1800613bb74b4cada2443876d04a6"
      process.env.REACT_APP_SHOPIFY_API_KEY || ""
    );
    setAppUrl(
      // uselink === "main"
      //   ? "https://vto-api.metadome.ai"
      //   : uselink === "staging"
      //     ? "https://vto-api-staging.metadome.ai"
      //     : uselink === "dev"
      //       ? "https://vto-api.metadome.ai"
      //       // : "https://1b1d-34-93-96-213.ngrok-free.app"
      //       : "https://vto-api.metadome.ai"
      process.env.REACT_APP_SHOPIFY_APP_URL || ""
    );
    console.log(
      "appId",
      process.env.REACT_APP_SHOPIFY_APP_EXTENSION_ID,
      process.env.REACT_APP_SHOPIFY_APP_URL
    );
    setAppExtensionId(process.env.REACT_APP_SHOPIFY_APP_EXTENSION_ID || "");
    setShop(paramsObject.shop || "");
    setHost(paramsObject.host || "");
    setIsEmbedded(Number(paramsObject.embedded) || 0);
    if (getShop() !== undefined && getShop() !== "") {
      if (
        paramsObject.embedded === undefined ||
        (paramsObject.embedded !== undefined && paramsObject.embedded === "0")
      ) {
        window.location.replace(
          `${getAppUrl()}/shopify-plugin/install?shop=${getShop()}`
        );
      }
      await verifyIsShopify(queryString);
      console.log("paramsObj", paramsObject, getShop());
    }

  return;
};

const verifyIsShopify = async (queryString : string) => {
  try {
    const response = await axios.get(
      `${getAppUrl()}/shopify-plugin/verify${queryString}`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );

    if (
      response.data &&
      response.data.isShopify &&
      response.data.isInstallationRequired !== undefined &&
      response.data.isFirstInstallation !== undefined
    ) {
      setIsShopify(response.data.isShopify);
      setIsInstallationRequired(response.data.isInstallationRequired);
      setIsFirstInstallation(response.data.isFirstInstallation);
      if (response.data.isInstallationRequired) {
        const config = {
          apiKey: getApiKey(),
          host: getHost(),
          forceRedirect: true,
        };
        const app = createApp(config);
        const redirect = Redirect.create(app);
        redirect.dispatch(
          Redirect.Action.REMOTE,
          `${getAppUrl()}/shopify-plugin/install?shop=${getShop()}`
        );
      }
    }
    setIsVerificationComplete(true);
  } catch (error) {
    console.error("Failed to Verify", error);
  }
};