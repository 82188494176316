import React from "react";
import classes from "./Welcome.module.scss";
import { Card, Text, Divider, Page, Box, Icon } from "@shopify/polaris";
import { ArrowDownIcon } from "@shopify/polaris-icons";

interface Inprops{
  setShowWelcomeScreen: (id:boolean) => void
}

const Welcome = ({setShowWelcomeScreen}:Inprops) => {
  const steps = [
    {
      id: 1,
      label:
        "1. Configure VTO Button in you preferred position in Product Pages",
    },
    { id: 2, label: "2. Add Products where VTO is enabled" },
    { id: 3, label: "3. Configure VTO Categories for each product" },
    { id: 4, label: "4. Assign a Color to each product" },
    { id: 5, label: "5. Make it Live" },
  ];
  return (
    <div className={classes.wrapper}>
      <div className={classes.body}>
        <div className={classes.left}>
          <span className={classes.head}>
          Hey there, welcome to Metadome Virtual Makeup Try On!
          </span>
          <span className={classes.desc}>
          Let’s setup your Virtual Try On (VTO) experience. Follow the steps on the next screens to install, setup, and customize your VTO experience.
          </span>
          <div className={classes.bottom}>
            <button onClick={() => {
              setShowWelcomeScreen(false)
            }}>Get Started</button>
            <span>Read documentation</span>
          </div>
        </div>
        <div className={classes.right}>
          {/* <video src={`/assets/sunroof_en_audi.mp4`} autoPlay /> */}
          <img src="./assets/welcomeimg.png"/>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
