import axios from "axios";
import { getApp, getAppUrl, getShop } from "../components/GlobalVariables";
import { getSessionToken } from "@shopify/app-bridge/utilities";
import { setThemes } from "../store/slices/productsSlice";
import store from "../store/store";
import { Product } from "../interfaces/productIntegration";
import { appBlockId, appEmbedBlockId } from "./constants";

export const fetchThemes = async () => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  console.log("themes", shopName);
  if (!shopName) return;
  try {
    const response = await axios.get(
      `${app_server_url}/shopify-plugin/themes?shop=${shopName}`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    console.log("themes1", response);
    store.dispatch(setThemes(response?.data?.data?.themes?.nodes?.map((item : any)=>{
      
      const files : string[] = item?.files?.nodes.map((item : any)=>{
        return item?.body?.content;
      });
      return {
        gid: item.id,
        id: item.id.split('/').pop(),
        name: item.name,
        role: item.role,
        processing: item.processing,
        files: files,
      };
    })));
    return response
  } catch (e) {
    console.log("themes2", e);
    console.log(e);
  }
};

export const checkInstallations = async (id?:string) => {
  const shopName = getShop();
  const app_server_url = getAppUrl();
  if (!shopName) return;
  let mainItem:any
  if(id === undefined){
    await fetchThemes().then((response) => {
      response?.data?.data?.themes?.nodes?.map((item : any)=>{
        if(item.role === 'MAIN'){
          mainItem = item
        }
      })
    })
  }
  console.log("mainItem",mainItem)
  const Id = id ?? mainItem?.id
  try {
    const response = await axios.get(
      `${app_server_url}/shopify-plugin/checkAppBlocks?shop=${shopName}&id="${Id}"&appBlockId="${appBlockId}"&appEmbedBlockId="${appEmbedBlockId}"`,
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    return response?.data
  } catch (e) {
    console.log(e);
  }
};

const fetchLocalProducts = async () => {
  let LocalProducts: Product[] = [];
  const shopName = getShop();
  const app_server_url = getAppUrl();
  try {
    const response = await axios.post(
      `${app_server_url}/shopify-plugin/get-products?shop=${shopName}`,
      {},
      {
        headers: {
          "ngrok-skip-browser-warning": "69420",
          skip_zrok_interstitial: "69420",
          authorization: `Bearer ${await getSessionToken(getApp())}`,
        },
      }
    );
    LocalProducts = (response.data as any[]).map((item) => {
      return {
        id: item.productId,
        productId: item.productId,
        productName: item.productName,
        storeName: item.storeName,
        makeup: item.makeup,
        variants: item.variants,
        imageUrl: item?.imageUrl || "",
      } as Product;
    });
  } catch (error) {
    console.error("Failed to fetch local products:", error);
  }
  return LocalProducts ? LocalProducts : [];
};
