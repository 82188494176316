export const onboardingSteps = [
  {
    id: 1,
    name: "Setup and Configure VTO",
    heading: "Configure VTO Button",
  },
  {
    id: 2,
    name: "Add products to enable the try on",
    heading: "",
  },
  {
    id: 3,
    name: "",
    heading: "",
  }
];

export const categories = [
  { label: "Lipstick", value: "Lipstick" },
  { label: "Lipliner", value: "Lipliner" },
  { label: "Eyeshadow", value: "Eyeshadow" },
  { label: "Eyeliner", value: "Eyeliner" },
  { label: "Blush", value: "Blush" },
]

export const appBlockId = `shopify://apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/blocks/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_APP_BLOCK_NAME}/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_ID}`

export const appEmbedBlockId = `shopify://apps/${process.env.REACT_APP_SHOPIFY_APP_NAME}/blocks/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_APP_EMBED_BLOCK_NAME}/${process.env.REACT_APP_SHOPIFY_APP_EXTENSION_ID}`
